header.main-header {
    position: relative;
    background-color: $main-header-bg;
    padding: 25px 15px 10px 15px;
    a {
        user-select: none;
    }
    .nav-ul-survey {
        display: none;
    }
}

nav.main-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    //align-items: center;
}
.flag-container {
    display: flex;
    flex-direction: column;

    a {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            opacity: 30%;
        }
        &.flag-active {
            img {
                opacity: 100%;
            }
        }
        span {
            margin-left: 10px;
        }
        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

.nav-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
}

header.main-header.main-header-special {
    padding: 1rem 1.5rem;

    .nav-img-logo,
    .logo img {
        height: 50px;
        width: auto;
    }
}

header.main-header.main-header-logo {
    padding: 30px 15px 0 15px;
    nav.main-nav {
        //justify-content: center;
    }
}

header.main-header.header-with-content {
    nav {
    }

    .partnership {
        padding-left: 25px;
        display: flex;
    }
}

#nav-ul-wrapper {
    position: absolute;
    background-color: $dark-background;
    padding: 1rem;
    top: 73px;
    right: 25px;
    width: 250px;
    display: none;
    //transform: translateY(0px);
    //transition: all .1s linear;
    border: 2px solid #fff;
    z-index: 100;
    &.open {
        display: block;
    }
}
#nav-toggler {
    width: 40px;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    appearance: none;
    background-image: none;
    background: transparent;
    border: 0px none;
    border-radius: 0;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $nero;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
        top: 12px;
        width: 100%;
    }

    span:nth-child(4) {
        top: 24px;
        width: 100%;
    }

    &.open {
        span:nth-child(1) {
            top: 18px;
            width: 0%;
            right: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
            width: 100%;
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
            width: 100%;
        }

        span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
    img {
        width: 25px;
    }
}

header.is-live {
    .nav-ul-survey {
        display: list-item;
    }
}
.nav-ul {
    text-align: right;
    //	padding-left: 15px;
}
.nav-ul-item {
    margin-bottom: 15px;
}

.nav-ul-link {
    color: $text-color;
    font-size: 18px;
    text-transform: uppercase;
    display: inline-block;

    // &:before {
    //     content: attr(title);
    //     font-weight: 600;
    //     display: block;
    //     height: 1px;
    //     color: transparent;
    //     overflow: hidden;
    //     visibility: hidden;
    //     margin-bottom: -1px;
    // }

    &:hover,
    &:focus {
        color: $text-color;
        font-weight: 600;
    }

    //&.nav-ul-link-user {}

    &.nav-ul-link-active {
        font-weight: bold;
        position: relative;
        //text-decoration: underline;

        &:after {
            content: '';
            width: 45px;
            height: 3px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.nav-ul-link-user,
.link-user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }
}

.nav-logout {
    color: $text-color;
}

.ico-user {
    background-image: url('/assets/img/user-icon-white.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 8px;
}

#nav-toggler {
    position: absolute;
    right: 25px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid white;
    color: white;
    padding: 0 1rem 0 1rem;
    width: 110px;
    height: 50px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    appearance: none;
    background-image: none;
    background: transparent;
    border-radius: 0;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $text-color;
        border-radius: 9px;
        opacity: 1;
        right: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
        top: 12px;
        width: 70%;
    }

    span:nth-child(4) {
        top: 24px;
        width: 50%;
    }

    &.open {
        span:nth-child(1) {
            top: 18px;
            width: 0%;
            right: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
            width: 100%;
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
            width: 100%;
        }

        span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
    .language-selected {
        img.language-img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 1rem;
        }
        .language-text {
            display: none;
        }
    }
}

.nav-ul-item-dd.no-hover {
    #nav-logout {
        display: none !important;
    }

    .nav-ul-link {
        cursor: default;
    }
    .nav-ul-link:hover {
        font-weight: normal;
    }
}

@media (min-width: 768px) {
    header.main-header {
        .nav-logo {
            width: 200px;
        }
    }

    header.main-header.main-header-special {
        padding: 2rem 1.5rem 0.5rem 1.5rem;

        .nav-img-logo,
        .logo img {
            height: 75px;
            width: auto;
        }
    }

    header.main-header.header-with-content {
        padding-top: 50px;
        .partnership {
            width: 400px;
        }
        .partnership.large {
            width: 70%;
        }
    }

    #nav-toggler {
        width: 250px;
        .language-selected {
            img.language-img {
            }
            .language-text {
                display: block;
            }
        }
    }
    #nav-ul-wrapper {
        top: 98px;
    }
}
@media (min-width: 992px) {
    header.main-header {
        padding: 1rem 2rem;

        .nav-logo {
            width: 230px;
        }
    }

    #nav-toggler {
        right: 50px;
        .language-selected {
            img.language-img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin-right: 1rem;
            }
            .language-text {
            }
        }
    }

    #nav-ul-wrapper {
        right: 50px;
    }

    .nav-ul {
        display: flex;
        padding: 0;
    }

    .nav-ul-item-dd {
        position: relative;
        border-left: 3px solid #fff;

        &:hover #nav-logout {
            display: block;
        }
    }

    .nav-ul-item {
        margin: 0;

        &.first a {
            padding-left: 0px;
        }
        &.last a {
            padding-right: 0px;
        }
    }

    .nav-ul-link {
        margin: 0 20px;
        font-size: 20px;
    }

    .nav-ul-link-user {
        margin-right: 0;
    }

    #nav-logout {
        position: absolute;
        width: 100%;
        background-color: $light-background;
        padding: 10px 15px;
        text-align: right;
        top: 100%;
        left: 0;
        display: none;

        &:hover {
            display: block;
        }
    }

    .nav-logout {
        color: $text-reverse;
        font-weight: bold;

        &:hover {
            color: $text-highlight;
        }
    }

    header.main-header.main-header-special {
        padding: 2rem 0vw 0rem 2vw;

        max-width: 60%;

        .nav-img-logo,
        .logo img {
            height: 75px;
            width: auto;
        }
    }

    header.main-header.main-header-logo {
        //padding: 30px 15px 0 15px;
        nav.main-nav {
            justify-content: center;
        }
    }
}
@media (min-width: 1200px) {
    header.main-header {
        padding: 1.5rem 4vw 1rem 4vw;
    }

    .nav-ul-link {
        margin: 0 30px;
    }

    .nav-ul-item-dd {
        padding-left: 30px;
        margin-left: 30px;

        .nav-ul-link {
            margin-right: 0;
        }
    }
}
