.helpcrunch-iframe-wrapper {
    iframe {
        right: 10px !important;
        left: auto !important;
        top: 60px !important;
    }
}
#av-live {
    width: 100%;
    background-color: $av-live-background;
    .vjs-big-play-button {
        display: none;
    }
}

#av-content {
    position: relative;
    background-color: $av-content-background;
    padding: 0;

    .av-live-frame-border {
        border: 5px solid #fff;
        // background-color: $av-live-frame-border;
        // padding: 15px;
    }
}
#av-aside {
    position: relative;
    background-color: $av-aside-background;
    padding: 0 0 25px 0;
}

#btn-toggle-room-status {
    font-weight: bold;
    font-size: 22px;
    text-shadow: none;
    box-shadow: none;
}

#room-box {
    margin: 2rem auto;
    padding: 5px;
    //max-width: 500px;
    background-color: $room-box-background;
}
#room-box-sponsor {
    padding: 0 10px;
    max-width: 500px;
    margin: 0 auto;
}
#room-box-title {
    .title {
        background-color: #000;
        color: #fff;
        padding: 5px 15px;
        font-weight: bold;
        font-size: 16px;
    }
}
#video-box,
#iframe-box {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;

    #live {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

#av-live[data-av-theme='live-aside'] {
    #room-box {
    }
}

@media (min-width: 768px) {
    .helpcrunch-iframe-wrapper {
        iframe {
            right: 10px !important;
            left: auto !important;
            top: auto !important;
            bottom: 15px !important;
        }
    }
}

@media (min-width: 992px) {
    #av-live[data-av-theme='live-full-chat'] {
        // spazio verticale per area video
        // header 83px + footer 130 px + padding soprasotto (15*2) 30px = 243px

        width: min(100vw - 30px, (100vh - 83px - 130px - 30px) * 16/9);
        margin-left: auto;
        margin-right: auto;

        .video-js .vjs-fullscreen-control {
            margin-right: min(400px, 50%);
        }

        #room-box {
            margin: 0;
            width: min(400px, 50%);
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
        }
    }

    #av-live[data-av-theme='live-aside'] {
        justify-content: center;
        padding: 0 2rem;
        #av-aside {
            //max-width: 500px;
            padding-left: 25px;
        }
        #room-box {
            margin: 0;
        }
    }
}
@media (min-width: 768px) {
    // #room-box-title {
    //     top: 20px;

    //     .title {
    //         font-size: 30px;
    //     }
    // }
}
@media (min-width: 992px) {
    // #room-box-title {
    //     top: -20px;

    //     .title {
    //         font-size: 30px;
    //     }
    // }

    #av-aside {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
@media (min-width: 1200px) {
    #av-live[data-av-theme='live-aside'] {
        padding: 0 4rem;
    }
}

@media (min-width: 1400px) {
}
